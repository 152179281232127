@import "styles/_variables.scss";

.form {
    width: 100%;

    @media (min-width: $mobilePoint + 1) {
        &.small {
            display: flex;
            flex-wrap: wrap;
            column-gap: 20px;

            .form__block__item {
                width: calc(50% - 10px);
                margin-bottom: 24px;

                &__title {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 26px;

                    &.required {
                        &:after {
                            content: '*';
                            display: inline-block;
                            margin-left: 3px;
                            color: $error;
                            font-size: 18px;
                        }
                    }
                }

                &:nth-of-type(5),
                &:nth-of-type(6),
                &:nth-of-type(7) {
                    width: 100%;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

        }
    }

    &__block {
        &__item {
            &_flex {
                display: flex;

                @media(max-width: $mobilePoint) {
                    display: flex;
                    flex-direction: column;
                }
            }

            &__title {
                font-family: $fontFamily;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;
                color: #1D1D1F;
                margin-bottom: 12px;
            }

            &__text {
                width: 182px;
                min-width: 182px;
                font-family: $fontFamily;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                margin-left: 24px;
                text-align: right;

                @media(max-width: $mobilePoint) {
                    min-width: 163px;
                    margin-top: 6px;
                    text-align: left;
                }

                &_second {
                    color: #1D1D1F;
                    white-space: nowrap;
                    width: 100%;
                }

                &_black {
                    color: #70707C;
                }
            }

            margin-bottom: 32px;
        }
    }

    &__input {
        height: 48px;
        width: 100%;
        border: 1px solid #DFDEDF;
        outline: none;
        border-radius: 4px;
        background-color: $white;
        padding-left: 16px;
        font-size: 16px;

        &_file {
            opacity: 0;
            position: absolute;
            z-index: -1;
        }

        &_message {
            height: 140px;
            padding-top: 16px;
            resize: none;
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 17px;
            color: $mainText;
        }

        &:focus {
            border: 1px solid $greenBackground;
        }

        &::placeholder {
            color: $blogSecondText;
            font-family: $fontFamily;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 17px;
        }
    }

    &__button {
        width: 100%;
        height: 48px;
        background-color: $greenBackground;
        border-radius: 4px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: $white;
        outline: none;
        transition: all 0.3s linear;
        border: 1px solid $greenBackground;

        &:hover {
            transform: scale(1.02, 1.02);
            cursor: pointer;
            color: $greenBackground;
            background-color: $white;
        }

        &_dnd {
            width: 100%;
            height: 48px;
            border: 1px solid #DFDEDF;
            border-radius: 4px;
            display: flex;
            align-items: center;
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 27px;
            color: #70707C;

            @media(max-width: 1200px) {
                width: 100%
            }

            &__image {
                height: 20px;
                margin-left: 19px;
                margin-right: 11px;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
    &_hidden {
        display: none;
    }
}

.form__input_invalid {
    border: 1px solid $error;
}

.form__input_invalid:focus {
    border: 1px solid $error;
}

.form__invalid {
    transition: opacity .5s ease 0s;
    color: $error;
    font-family: $fontFamily;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 15px;
    margin-top: 6px;
    opacity: 1;
    display: block;
}

.budget {
    position: relative;

    &__input {
        display: block;
        position: relative;
        &:hover{
            cursor: pointer;
        }
    }

    &__arrow {
        position: absolute;
        height: 16px;
        width: 16px;
        transition: all 0.3s linear;
        right: 22px;
        top: 18px;
        transform: rotate(-180deg);

        &_active {
            transform: rotate(0deg);
        }

        svg {
            path {
                fill: #70707C;
            }
        }
    }

    &__list {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $mainText;
        margin-top: 8px;
        background: #FFFFFF;
        border: 1px solid #DFDEDF;
        border-radius: 4px;
        padding: 8px;
        position: absolute;
        width: 100%;
        display: none;
        z-index: 1000;

        &_active {
            display: block;
        }

        &__item {
            padding: 13px 0 14px 12px;
            border-radius: 4px;

            &:hover {
                cursor: pointer;
                background: #F5F3F0;
            }
        }
    }
}

.detail__form__block__input {
    :global {
        .detail__form__input {
            border: 1px solid $bloghrColor !important;
            background-color: $white !important;
            box-sizing: border-box !important;
            border-radius: 5px !important;
            width: 100% !important;
            height: 49px !important;
            color: $blogSecondText !important;
            font-family: $fontFamily !important;
            font-size: 16px !important;
            font-weight: 500 !important;
            letter-spacing: 0 !important;
            line-height: 17px !important;
            padding-left: 100px !important;

            &:focus {
                border: 1px solid #024D40 !important;

                & + .detail__form__btn {
                    border: 1px solid #024D40 !important;
                }
            }
        }

        .detail__form__btn {
            background-color: #fff !important;

            & .selected-flag {
                padding: 0 0 0 20px !important;
                width: 84px !important;
            }

            & .flag {
                scale: 1.5;

                & .arrow {
                    background-image: url(/assets/icons/icn-arrow.svg);
                    background-size: contain;
                    width: 16px;
                    height: 16px;
                    border: 0;
                    background-repeat: no-repeat;
                    scale: 0.6666;
                    margin-top: -7px;
                    left: 22px;
                    transition: all 0.3s linear;
                    opacity: 0.7;
                    transform: rotate(-180deg);
                    transform-origin: center;
                }

                & .up {
                    transform: rotate(0deg);
                    border-bottom: none !important;
                }
            }
        }

        .detail__form__dropdown {
            border: 1px solid #DFDEDF !important;
            box-shadow: 0 6px 40px rgba(29, 29, 31, 0.08) !important;
            border-radius: 4px !important;
            padding: 8px 0 !important;

            & .country-name {
                font-size: 16px;
                line-height: 26px;
                color: #1D1D1F;
                margin: 0 8px 0 12px;
            }

            & .country {
                padding: 7px 9px 7px 19px !important;

                &.highlight,
                &:hover {
                    background-color: #F5F3F0 !important;
                }
            }
        }
    }
}

.file__error{
    margin-bottom: 16px;
}
